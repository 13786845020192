<script>
	import Draggable from 'vuedraggable'
	import Dropzone from '../Commons/Dropzone'
	import Datepicker from '../Commons/Datepicker'
	import {Table} from '../../mixins/table'
	import {Form} from '../../helpers/Form/Form'

	export default {
		mixins: [Table],
		props: {
			room: Number,
			categories: Array,
			templates: Array
		},
		components: {Dropzone,Datepicker,Draggable},
		data() {
			return {
				codes: [],
				positions: [],
				targets: [],
				editor: null,
				current_tab: 'view-input-fields',
				option: {
					label: null,
					value: null,
					open: false,
					color: '#000000',
					cover: null,
					weight: 0
				},
				child: null,
				child_target: null,
				form: new Form({
					positions: [],
					post_last_position: 0,
					id: null,
					survey_id: null,
					template_id: null,
					type: null,
					category_id: null,
					gdpr_ctrl: false,
					gdpr_url: null,
					name: null,
					code: null,
					position: null,
					description: null,
					doneif: 0,
					depend_to: null,
					cover: null,
					status: false,
					enable_notifications: false,
					show_comments: 'all',
					publish_at: null,
					unpublish_at: null,
					show_target: false,
					show_publish: false,
					show_unpublish: false,
					targets_list: [],
					groups: [],
					parent_id: null,
					survey: {
						id: null,
						name: null,
						sections: [],
						questions: []
					},
					table: {
						rows: null,
						columns: [],
						required_rows: []
					},
					posts: []
				}),
				fetch: new Form({
					backend: 1
				}),
				group: {
					rooms: [],
					targets: [],
					orders: []
				},
				rooms: [],
				pagination: {}
			}
		},
		computed: {
			source() {
				return !_.isNumber(this.room) ? route('rooms.store') : route('rooms.update', this.room)
			},
			method() {
				return !_.isNumber(this.room) ? 'post' : 'put'
			},
			filterRooms() {
				let rooms = _.uniqBy(_.flatMap(this.form.groups, 'rooms'), 'id')

				if (rooms.length > 0) {
					return this.rooms.filter(i => {
						let idx = _.findIndex(rooms, r => r.id == i.id)
						return idx < 0
					})
				}

				return this.rooms
			},
			lastPosition() {
				if (this.positions.length == 0) {
					return 1
				}

				let keys = Object.keys(this.positions)
				if (keys.length > 0) {
					return this.positions[_.last(keys)] + 1
				}
			}
		},
		watch: {
			'form.type': {
				handler(val) {
					if (val == 'rotation') {
						this.getRooms()
					}

					this.$bus.$emit('room:update', val)
				}
			},
			lastPosition(val) {
				if (!_.isNumber(this.form.id)) {
					this.$set(this.form, 'position', val)
				}
			},
			'form.show_unpublish': {
				handler(val) {
					if (!val) {
						this.$set(this.form, 'unpublish_at', null)
					}
				}
			},
			'form.show_publish': {
				handler(val) {
					if (!val) {
						this.$set(this.form, 'publish_at', null)
					}
				}
			},
			'form.category_id': {
				handler(val) {
					let category = this.categories.find(i => i.id == val)
					if (category) {

						if(!this.form.publish_at) this.$set(this.form, 'publish_at', category.publish_at ? category.publish_at : this.form.publish_at)
						if(!this.form.unpublish_at) this.$set(this.form, 'unpublish_at', category.unpublish_at ? category.unpublish_at : this.form.unpublish_at)
						this.$set(this.form, 'targets_list', category.targets_list)
						if(!this.form.show_publish) this.$set(this.form, 'show_publish', !_.isNull(category.publish_at))
						if(!this.form.show_unpublish) this.$set(this.form, 'show_unpublish', !_.isNull(category.unpublish_at))
						this.$set(this.form, 'show_target', category.targets_list.length > 0)
					} else {
						this.$set(this.form, 'publish_at', this.form.publish_at)
						this.$set(this.form, 'unpublish_at', this.form.unpublish_at)
						this.$set(this.form, 'targets_list', this.form.targets_list)
						this.$set(this.form, 'show_publish', !_.isNull(this.form.publish_at))
						this.$set(this.form, 'show_unpublish', !_.isNull(this.form.unpublish_at))
						this.$set(this.form, 'show_target', this.form.targets_list.length > 0)
					}
				}
			},
			'form.doneif': {
				handler(val) {
					this.$bus.$emit('rooms.show', this.form.data())
				}
			}
		},
		mounted() {
			this.getLastPositions()
			this.initEditor()
			this.fetchTargets()
			this.fetchRoom()

			this.$bus.$on('posts.saved', this.updatePosts)
		},
		updated() {
			this.initEditor()

			this.initPlugin()
		},
		methods: {
			async updatePosition(data = {}) {
				this.form.posts.forEach((i, k) => {
					this.$set(i, 'position', k)
				})

				this.form.positions = this.form.posts.map(p => {
					return {
						id: p.id,
						position: p.position
					}
				})

				await this.form.submit('post', route('api.posts.update_positions'))
			},
			updatePosts(payload) {
				if (_.isNumber(payload.idx)) {
					let idx = this.form.posts.findIndex(i => i.id == payload.data.id)
					if (idx >= 0) {
						this.$set(this.form.posts, payload.idx, payload.data)
					}
				} else {
					this.form.posts.push(payload.data)
				}
			},
			onOpenPostFormModal() {
				this.$bus.$emit('post-modal.show')
			},
			getLastPositions() {
				this.fetch
					.get(route('api.rooms.positions'))
					.then(response => {
						this.$set(this, 'positions', response.positions)
						this.$set(this, 'codes', response.codes)

						if (_.isNull(this.form.id)) {
							this.$set(this.form, 'position', this.lastPosition)
						}
					})
			},
			uniqId(item) {
				return Math.random().toString()
			},
			getFilterGroups(group) {
				return this.form.groups.filter(i => i.id != group.id)
			},
			merge(idx) {
				let merge = []

				if (this.form.groups.length > 1) {
					let groups = _.map(this.form.groups, (i, idx) => {
						return {
							id: i.id,
							name: i.name
						}
					})

					merge.push({
						target: this.form.groups[idx].targets,
						items: groups
					})
				} else {
					this.form.groups[idx].targets.forEach(i => {
						merge.push({
							target: i,
							items: _.cloneDeep(this.form.groups[idx].rooms)
						})
					})
				}

				this.$set(this.form.groups[idx], 'orders', merge)
			},
			onCreateGroup() {
				let length = this.form.groups.length
				this.form.groups.push(Object.assign(
					{
						id: Math.random().toString(),
						name: `GRUPPO ${length + 1}`,
						depend_to: null
					},
					this.group
				))

				this.group = {
					rooms: [],
					targets: [],
					orders: []
				}

				if (this.form.groups.length > 1) {
					this.form.groups.forEach(i => {
						i.orders = []
					})
				}
			},
			onRemoveChild(idx) {
				this.form.children.splice(idx, 1)
				let children = _.cloneDeep(this.form.children)

				this.form.children.forEach(i => {
					i.child_order = this.form.children
				})
			},
			getRooms() {
				this.fetch
					.submit('post', route('api.rooms.children'))
					.then(response => {
						this.$set(this, 'rooms', response.rooms)
					})
			},
			initPlugin() {
				let vm = this

				$("select").formSelect();
				$(`.dropdown-trigger`).dropdown({
					alignment: 'right',
					coverTrigger: true,
					constrainWidth: false
				})
			},
			fetchTargets() {
				this.fetch
					.get(route('api.targets.index'))
					.then(response => {
						this.$set(this, 'targets', response.targets)
					})
			},
			async fetchRoom() {
				if (_.isNumber(this.room)) {
					await this.fetch
						.get(route('api.rooms.show', this.room), {})
						.then(response => {
							this.initRoom(response)

							this.$bus.$emit('rooms.show', response)
						})
				} else {
					this.$bus.$emit('rooms.show', this.form.data())
				}
			},
			initRoom(response) {
				let room = _.omit(response.room, 'survey')
				this.$set(this, 'pagination', response.pagination)

				for (let prop in room) {
					if (this.form.hasOwnProperty(prop)) {
						if (prop == 'groups') {
							this.$set(this.form, prop, this.initGroups(room))
						} else {
							this.$set(this.form, prop, room[prop])
						}
					}
				}
				this.$set(this.form, 'show_publish', !_.isNull(this.form.publish_at)) //
				this.$set(this.form, 'show_unpublish', !_.isNull(this.form.unpublish_at))
				this.$set(this.form, 'show_target', this.form.targets_list.length > 0)

				this.$set(this.form, 'survey', response.survey)
				this.$set(this.form, 'table', response.table)
				this.editor.root.innerHTML = this.form.description
			},
			initGroups({groups}) {
				let groupsData = []
				groups.forEach(i => {
					let orders = []
					let targets = []
					let items = []
					let decodeOrder = null

					if (groups.length > 1) {
						targets = _.head(i.targets)
						decodeOrder = JSON.parse(targets.pivot.order_groups)
					} else {
						targets = i.targets
					}

					i.targets.forEach(t => {
						if (t.pivot.order_groups) {
							decodeOrder = JSON.parse(t.pivot.order_groups)
							decodeOrder.forEach(o => {
								let idx = _.findIndex(groups, g => g.name == o)
								items.push(groups[idx])
							})

							orders.push({
								target: t,
								items: items
							})
						} else if (t.pivot.order_rooms) {
							let rooms = []
							decodeOrder = JSON.parse(t.pivot.order_rooms)
							decodeOrder.forEach(o => {
								let idx = _.findIndex(i.items, r => r.id == o)
								if (idx >= 0) {
									rooms.push(i.items[idx])
								}
							})

							orders.push({
								target: t,
								items: rooms
							})
						}
					})

					groupsData.push({
						id: i.id,
						name: i.name,
						rooms: i.items,
						targets: targets,
						orders: orders
					})
				})

				return groupsData
			},
			canSelectTarget(group) {
				return !_.isNumber(group.id)
			},
			isActivate(tab) {
				return this.current_tab == tab
			},
			activeTab(tab) {
				this.$set(this, 'current_tab', tab)
			},
			initEditor() {
				if ($(".room-full-editor .ql-toolbar").length) {
					return
				}

				this.editor = new Quill(this.$refs.roomEditor, {
					bounds: this.$refs.roomEditor,
					modules: {
					  	'formula': true,
					  	'syntax': true,
					  	'toolbar': {
					  		container: [
							    [{'font': []}, {'size': []}],
						    	['bold', 'italic', 'underline', 'strike'],
						    	[{'color': []}, {'background': []}],
						    	[{'script': 'super'}, {'script': 'sub'
						    	}],
						    	[{'header': '1'}, {'header': '2'}, 'blockquote', 'code-block'],
						    	[{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
						    	['direction', {'align': []}],
						    	['link', 'image', 'video'],
						    	['clean'],
						    	['emoji']
					  		],
					  	},
						'emoji-toolbar': true,
						'emoji-shortname': true,
					},
					theme: 'snow'
				});
				// add browser default class to quill select
				let quillSelect = $("select[class^='ql-'], input[data-link]");
				quillSelect.addClass("browser-default");

		        this.editor.on('text-change', (e) => {
			    	this.$set(this.form, 'description', this.editor.root.innerHTML);
			    });

			    this.editor.root.innerHTML = this.form.description
			},
			addSection() {
				this.form.survey.sections.push({
					id: null,
					name: null,
					questions: []
				})
			},
			addQuestion() {
				this.form.survey.questions.push({
					id: null,
					title: null,
					content: null,
					type: null,
					show_graph: false,
					graph_type: null,
					rules: 'required',
					options: [],
					settings: {
						min: {
							label: null,
							value: null,
							weight: null
						},
						max: {
							label: null,
							value: null,
							weight: null
						}
					},
				})
			},
			addQuestionToSection(idx) {
				this.form.survey.sections[idx].questions.push({
					id: null,
					title: null,
					content: null,
					type: null,
					show_graph: false,
					graph_type: null,
					rules: 'required',
					options: [],
					settings: {
						min: {
							label: null,
							value: null,
							weight: null
						},
						max: {
							label: null,
							value: null,
							weight: null
						}
					},
				})
			},
			addOption(qIdx, idx = null) {
				let copy = _.cloneDeep(this.option)

				if (!_.isNull(idx)) {
					this.form.survey
						.sections[idx]
						.questions[qIdx]
						.options.push(copy)
				} else {
					this.form.survey
						.questions[qIdx]
						.options.push(copy)
				}

				this.option = {
					label: null,
					value: null,
					open: false,
					color: '#000000',
					cover: null,
					weight: 0
				}
			},
			removeOption(oIdx, idx = null) {
				this.form.survey.questions[idx].options.splice(oIdx, 1);
			},
			onRemoveQuestion(idx) {
				let question = this.form.survey.questions[idx]

				if (!_.isNumber(question.id)) {
					this.form.survey.questions.splice(idx, 1)
				} else {
					this.form
						.submit('delete', route('surveys.delete_question', question.id))
						.then(response => {
							this.form.survey.questions.splice(idx, 1)
						})
				}
			},
			onRemoveSectionQuestion(qIdx, idx) {
				let question = this.form.survey.sections[idx].questions[qIdx]

				if (!_.isNumber(question.id)) {
					this.form.survey.sections[idx].questions.splice(qIdx, 1)
				} else {
					this.form
						.submit('delete', route('surveys.delete_question', question.id))
						.then(response => {
							this.form.survey.sections[idx].questions.splice(qIdx, 1)
						})
				}
			},
			onRemoveGroup(idx) {
				let section = this.form.survey.sections[idx]

				if (!_.isNumber(section.id)) {
					this.form.survey.sections.splice(idx, 1)
				} else {
					this.form
						.submit('delete', route('surveys.delete_section', section.id))
						.then(response => {
							this.form.survey.sections.splice(idx, 1)
						})
				}
			},
			updateOptionValue(e) {
				if (e.target.checked) {
					this.$set(this.option, 'value', 'other')
				} else {
					this.$set(this.option, 'value', null)
				}
			},
			async onDeleteGroup(idx) {
				let group = this.form.groups[idx]

				if (_.isNumber(group.id)) {
					await this.form
						.submit('delete', route('api.rooms.delete_group', group.id))
						.then(response => {
							this.form.groups.splice(idx, 1);
						})
				} else {
					this.form.groups.splice(idx, 1);
				}

				this.updateGroups()
			},
			updateGroups() {
				this.form.groups.forEach((g, g_idx) => {
					let merge = []
					if (this.form.groups.length > 1) {
						let groups = _.map(this.form.groups, (i, idx) => {
							return {
								id: i.id,
								name: `GRUPPO ${idx}`
							}
						})

						merge.push({
							target: this.form.groups[idx].targets,
							items: groups
						})
					} else {
						merge.push({
							target: this.form.groups[g_idx].targets,
							items: _.cloneDeep(this.form.groups[g_idx].rooms)
						})

						this.$set(this.form.groups[g_idx], 'targets', [this.form.groups[g_idx].targets])
					}

					this.$set(this.form.groups[g_idx], 'orders', merge)
				})
			},
			async onSubmit() {
				await this.form
					.submit(this.method, this.source)
					.then(async response => {
						if (!_.isNumber(this.form.id)) {
							location.href = route('rooms.index')
						} else {
							await this.fetchRoom()

							this.$swal({
								icon: 'success',
								text: response.message
							})
						}
					})
			},
			async onRemovePost(idx) {
				let post = this.form.posts[idx]

				if (post.hasOwnProperty('id')) {
					await this.form.submit('delete', route('api.posts.destroy', post.id))
				}

				this.form.posts.splice(idx, 1)
			},
			onEditPost(idx, post) {
				this.$bus.$emit('post-modal.show', {idx, post})
			}
		}
	}
</script>
