require('./bootstrap')

window.Vue = require('vue');

import route from 'ziggy';
import { Ziggy } from './ziggy';
import VueSweetalert2 from 'vue-sweetalert2';

Vue.prototype.$bus = new Vue();
Vue.config.devtools = true;
Vue.use(VueSweetalert2);

Vue.component('chat-container', require('./components/Chat/ChatContainer').default)
Vue.component('room-reminder-modal', require('./components/Rooms/RoomReminderModal').default)
Vue.component('user-btn-delete', require('./components/Users/UserBtnDelete').default)
Vue.component('user-form', require('./components/Users/UserForm').default)
Vue.component('post-list', require('./components/Posts/PostList').default)
Vue.component('post-timeline-list', require('./components/Posts/PostTimelineList').default)
Vue.component('post-comment-list', require('./components/Posts/PostCommentList').default)
Vue.component('reactable-picker', require('./components/Commons/ReactablePicker').default)
Vue.component('reaction-list-modal', require('./components/Commons/ReactionListModal').default)
Vue.component('comment-textarea', require('./components/Comments/CommentTextarea').default)
Vue.component('comments-filter-form', require('./components/Comments/CommentsFilterForm').default)
Vue.component('comment-list-container', require('./components/Comments/CommentListContainer').default)
Vue.component('comment-media-container', require('./components/Comments/CommentMediaContainer').default)
Vue.component('full-calendar-container', require('./components/Commons/FullCalendarContainer').default)
Vue.component('room-form', require('./components/Rooms/RoomForm').default)
Vue.component('room-pagination', require('./components/Rooms/RoomPagination').default)
Vue.component('room-category-form', require('./components/Rooms/RoomCategoryForm').default)
Vue.component('room-categories-list-container', require('./components/Rooms/RoomCategoriesListContainer').default)
Vue.component('rooms-list-container', require('./components/Rooms/RoomsListContainer').default)
Vue.component('room-notifiable-modal-container', require('./components/Rooms/RoomNotifiableModalContainer').default)
Vue.component('room-comparison-table', require('./components/Rooms/RoomComparisonTable').default)
Vue.component('room-setting-order-container', require('./components/Rooms/RoomSettingOrderContainer').default)
Vue.component('survey-container', require('./components/Surveys/SurveyContainer').default)
Vue.component('survey-question-graph', require('./components/Surveys/SurveyQuestionGraph').default)
Vue.component('survey-question-export-button', require('./components/Surveys/SurveyQuestionExportButton').default)
Vue.component('ssi-container', require('./components/Surveys/SsiContainer').default)
Vue.component('translation-string-container', require('./components/Translations/TranslationStringContainer').default)
Vue.component('translator-entities-modal', require('./components/Translations/TranslatorEntitiesModal').default)
Vue.component('languages-container', require('./components/Translations/LanguagesContainer').default)
Vue.component('invite-send-modal', require('./components/Invites/InviteSendModal').default)
Vue.component('invite-form', require('./components/Invites/InviteForm').default)
Vue.component('invite-list', require('./components/Invites/InviteList').default)
Vue.component('project-list-container', require('./components/Projects/ProjectListContainer').default)
Vue.component('comment-notifiable-modal-container', require('./components/Comments/CommentNotifiableModalContainer').default)
Vue.component('post-form', require('./components/Posts/PostForm').default)
Vue.component('post-list-container', require('./components/Posts/PostListContainer').default)
Vue.component('post-list-container', require('./components/Posts/PostListContainer').default)
Vue.component('post-translator-container', require('./components/Posts/PostTranslatorContainer').default)
Vue.component('comment-translator-container', require('./components/Comments/CommentTranslatorContainer').default)
Vue.component('page-form-container', require('./components/Pages/PageFormContainer').default)
Vue.component('post-notifiable-modal-container', require('./components/Posts/PostNotifiableModalContainer').default)
Vue.component('comment-thread-user-modal-container', require('./components/Comments/CommentThreadUserModalContainer').default)
Vue.component('export-form-container', require('./components/Exports/ExportFormContainer').default)
Vue.component('social-export-form-container', require('./components/Exports/SocialExportFormContainer').default)
Vue.component('media-export-form-container', require('./components/Exports/MediaExportFormContainer').default)
Vue.component('video-export-form-container', require('./components/Exports/VideoExportFormContainer').default)
Vue.component('users-export-form-container', require('./components/Exports/UsersExportFormContainer').default)
Vue.component('account-mail-form-container', require('./components/Mails/AccountMailFormContainer').default)
Vue.component('reactable-modal-picker', require('./components/Commons/ReactableModalPicker').default)
Vue.component('user-private-messages-container', require('./components/Users/UserPrivateMessagesContainer').default)
Vue.component('user-private-thread-container', require('./components/Users/UserPrivateThreadContainer').default)

if (document.getElementById('main')) {
	Vue.mixin({
	    methods: {
	        route: (name, params, absolute) => route(name, params, absolute, Ziggy),
	    },
	});

	const app = new Vue({
	    el: '#main',
	})
}
