<script>
	import Dropzone from '../Commons/Dropzone'
	import {Form} from '../../helpers/Form/Form'

	export default {
		props: ['roomId', 'postId', 'sponsoredLabel'],
		components: {Dropzone},
		data() {
			return {
				container: [
						[{'font': []}, {'size': []}],
						['bold', 'italic', 'underline', 'strike'],
						[{'color': []}, {'background': []}],
						[{'script': 'super'}, {'script': 'sub'
						}],
						[{'header': '1'}, {'header': '2'}, 'blockquote', 'code-block'],
						[{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
						['direction', {'align': []}],
						['link', 'image', 'video'],
						['clean'],
						['emoji']							
				],
				containerSocial: [
						[{'font': []}, {'size': []}],
						['bold', 'italic', 'underline', 'strike'],
						[{'color': []}, {'background': []}],
						[{'script': 'super'}, {'script': 'sub'
						}],
						[{'header': '1'}, {'header': '2'}, 'blockquote', 'code-block'],
						[{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
						['direction', {'align': []}],
						['link', 'image'],
						['clean'],
						['emoji']						
				],
				roomType: null,
				postPositions: 0,
				currentPosition: null,
				postIndex: null,
				room: {
					doneif: null
				},
				editor: {body: null, social_body: null, sponsored_body: null},
				fetch: new Form({}),
				form: new Form({
					author: null,
					locale: null,
					postable_id: null,
					id: null,
					title: null,
					position: null,
					body: null,
					audios: [],
					images: [],
					files: [],
					videos: [],
					video_url: null,
					required: false,
					sponsored: false,
					sponsored_title: null,
					sponsored_body: null,
					sponsored_label: this.sponsoredLabel,
					social_body: null,
					logo: null,
					mime_type: 'file',
				}),
				upload: new Form({
					file: null,
					site: process.env.MIX_APP_SITE_ID
				})				
			}
		},
		computed: {
			source() {
				if (!this.$refs.modal) {
					return this.form.id ? 
						route('rooms.posts.update', {room: this.form.postable_id, post: this.form.id}) : 
						route('rooms.posts.store', this.roomId)	
				} else {
					if (this.room.hasOwnProperty('id') && this.room.id && !this.form.id) {
						return route('rooms.posts.store', this.room.id)
					}

					return this.form.id ? 
						route('rooms.posts.update', {room: this.form.postable_id, post: this.form.id}) : 
						route('api.posts.validate')				
				}
			},
			method() {
				return this.form.id ? 'put' : 'post'
			}
		},
		mounted() {
			this.initEditor(
				this.$refs.editorContainer,
				this.$refs.editor,
				'body',
				this.container
			)

			this.initEditor(
				this.$refs.editorSocialContainer,
				this.$refs.editorSocial,
				'social_body',
				this.containerSocial
			)
			this.initEditor(
				this.$refs.sponsoredEditorContainer,
				this.$refs.sponsoredEditor,
				'sponsored_body',
				this.containerSocial
			)			
			this.initModal()

			this.$bus.$on('post-modal.show', this.init)
			this.$bus.$on('rooms.show', this.initRoom)
			this.$bus.$on('room:update', this.initType)

			if (this.postId) {
				this.init()
			}
		},
		updated() {
			$("select").formSelect();
		},
		methods: {
			initType(val) {
				this.$set(this, 'roomType', val)
				this.$set(this.room, 'type', val)
			},

			onClose() {
				$(this.$refs.modal).modal('close')
				this.form.reset()
				this.$set(this.form, 'recipients', [])

				for (let prop in this.editor) {
					if (this.editor[prop]) {
						this.editor[prop].root.innerHTML = null
					}
				}
			},
			initRoom(payload) {
				this.$set(this, 'room', payload)
				this.$set(this, 'postPositions', payload.post_last_position)
				this.$set(this.form, 'position', payload.post_last_position)
			},
			async init(payload = {}) {
				if (this.postId) {
					let response = await this.fetch.get(route('api.posts.show', this.postId))
					payload = response
				}

				this.$set(this, 'postIndex', payload.hasOwnProperty('idx') ? payload.idx : null)
				if (payload.hasOwnProperty('post')) {
					this.initForm(payload)
				}

				if (this.$refs.modal) {
					$(this.$refs.modal).modal('open')
				}
			},
			initForm(payload) {
				for (let prop in payload.post) {
					if (this.form.hasOwnProperty(prop)) {
						this.$set(this.form, prop, payload.post[prop])
					}
				}

				if (payload.post.hasOwnProperty('images')) {
					payload.post.images.forEach(i => {
						this.form.files.push({
							id: i.id,
							file: {
								name: i.file_name
							}
						})
					})

					payload.post.audios.forEach(i => {
						this.form.files.push({
							id: i.id,
							file: {
								name: i.file_name
							}
						})
					})			
				}		
				
				for (let prop in this.editor) {
					if (this.editor[prop]) {
						this.editor[prop].root.innerHTML = this.form[prop]
					}
				}

				if (payload.post.type == 'socialemulator') {
					this.form.social_body = payload.post.body
					this.form.body = null
				}
			},
			initModal() {
				let vm = this

				$(this.$refs.modal).modal({
					dismissible: false,
					onCloseEnd: function() {
						vm.form.errors.clear()
						vm.form.reset()		

						vm.$set(vm.form, 'position', vm.postPositions)

						for (let prop in vm.editor) {
							vm.editor[prop].root.innerHTML = null
						}
					}					
				})
			},			
			initEditor(container, el, prop, toolbar) {
				if ($(".ql-toolbar", container).length) {
					return
				}

				this.editor[prop] = new Quill(el, {
					bounds: container,
					modules: {
						'formula': true,
						'syntax': true,
						'toolbar': {
							container: toolbar,		  		
						},
						'emoji-toolbar': true,
						'emoji-shortname': true,			  	
					},
					theme: 'snow'
				});
				// add browser default class to quill select 
				let quillSelect = $("select[class^='ql-'], input[data-link]");
				quillSelect.addClass("browser-default");

				this.editor[prop].on('text-change', (e) => {
					this.$set(this.form, prop, this.editor[prop].root.innerHTML);
				});		

				this.editor[prop].root.innerHTML = this.form[prop]
			},
			async onSubmit() {
				let videos = this.getVideos()

				for (let i in videos) {
					let formData = new FormData
					formData.append('file', videos[i].file)
					formData.append('site', process.env.MIX_APP_SITE_ID)

					let {media,url} = await this.upload.submitWith('post', process.env.MIX_APP_VIDEO_UPLOAD_URL, formData)

					this.form.videos.push({
						id: media.id,
						mime: media.mime_type,
						url
					})
				}

				this.form
					.submitWith('post', this.source, this.initFormData())
					.then(response => {
						if (response.hasOwnProperty('post')) {
							this.$bus.$emit('posts.saved', {
								data: response.post,
								idx: this.postIndex
							})
						} else {
							this.$bus.$emit('posts.saved', {
								data: this.form.data(),
								idx: this.postIndex
							})

							if (this.form.position == this.postPositions) {
								this.$set(this, 'postPositions', this.postPositions + 1)
								this.$set(this.form, 'position', this.postPositions)
							}
						}
						
						if (!this.form.id && this.roomId) {
							location.href = route('rooms.posts.index', this.roomId)
						}
						this.$swal({
							icon: 'success',
							text: response.message			
						})							

						if (this.$refs.modal) {
							$(this.$refs.modal).modal('close')
						}
					})
			},

			getVideos() {
				let videos = []
				this.form.files.forEach((i, idx) => {
					if (typeof i == 'object') {
						if (_.startsWith(i.mime, 'video')) {
							videos.push(i)

							this.form.files.splice(idx, 1)
						}
					}
				})			

				return videos	
			},			

			initFormData() {
				let formData = new FormData
				let data = this.form.data()

				formData.append('_method', this.method.toUpperCase())

				for (let prop in data) {
			    if (prop == 'videos') {
						data[prop].forEach((v, idx) => {
							formData.append(`videos[${idx}][id]`, v.id)	
							formData.append(`videos[${idx}][mime]`, v.mime)	
							formData.append(`videos[${idx}][url]`, v.url)	
						})
					} else if (prop == 'video_url') {
						if (data[prop]) {
							formData.append(`videos[${this.form.videos.length}][id]`, Math.random())
							formData.append(`videos[${this.form.videos.length}][mime]`, `video/${data.mime_type}`)
							formData.append(`videos[${this.form.videos.length}][url]`, data.video_url)
							formData.append(`videos[${this.form.videos.length}][source]`, data.mime_type)
						}
					} else if (prop == 'files') {
						data[prop].forEach((f, idx) => {
							formData.append(`files[${idx}]`, f.file)	
						})
					} else if (prop == 'logo') {
						formData.append('logo', data[prop] ? data[prop].file : '')
					} else if (prop == 'position') {
						formData.append('position', data[prop])
					} else {
						if (typeof data[prop] == 'boolean') {
							formData.append(prop, data[prop] ? 1 : 0)
						} else {
							formData.append(prop, data[prop] ? data[prop] : '')
						}
					}
				}

				return formData
			},

			async onDeleteMedia(idx, type) {
				if (['images','audios'].includes(type)) {
					const media = this.form[type][idx]
					await this.form.submit('delete', route('media.destroy', media.id))
				}

				this.form[type].splice(idx, 1)
			}
		}
	}
</script>