<script>
	import data from 'emoji-mart-vue-fast/data/facebook.json'
	import Reactable from '../Commons/Reactable'
	import ReactionList from '../Commons/ReactionList'	
	import VideoPlayer from '../Commons/VideoPlayer'
	import AudioPlayer from '../Commons/AudioPlayer'	
	import CommentTree from './CommentTree'
	import FacebookReaction from '../Commons/FacebookReaction'
	import {Form} from '../../helpers/Form/Form'
	import {Loggable} from '../../mixins/loggable'

	export default {
		mixins: [Loggable],
		props: {
			data: Object,
			author: Number,
			showTranslationLabel: String,
			showCloseTranslationLabel: String
		},
		components: {
			Reactable,
			ReactionList,
			VideoPlayer,
			AudioPlayer,
			CommentTree,
			FacebookReaction
		},
		data() {
			return {
				post: this.data,
				comments: [],
				pagination: {},
				links: {},
				fetch: new Form({}),
				showComment: false,
				form: new Form({
					author_id: this.author,
					targets: [],
				}),
				reactForm: new Form({
					emoji: null,
					class_path: this.data.class_path
				})		
			}
		},
		computed: {
			reactableClass() {
				return this.post.is_reactable ? '#0D6FFD' : ''
			},

			counterReactions() {
				return _.uniq(_.flatMap(this.post.emojis.map(i => i.users))).length
			},

			draftSource() {
				return route('posts.comment_draft', this.post.id)
			},
			source() {
				return route('comments.store', this.post.id)
			},
			loadMore() {
				if (!_.isEmpty(this.pagination)) {
					return this.pagination.current_page < this.pagination.last_page
				}
				return this.comments.length > 0
			},			
			commentsSource() {
				if (!_.isEmpty(this.links)) {
					return this.links.next
				}
				return route('post_comments.index', this.post.id)
			},
			hasTranslation() {
					let translations = this.post.translations
					if (Array.isArray(translations.body)) {
							return false
					}

					return translations.body.hasOwnProperty('it')
			}
		},
		mounted() {
			this.getComments()

			this.$bus.$on(`${this.post.class_path}-${this.post.id}`, this.updateReaction)
			this.$bus.$on(`${this.post.id}.comments.filter`, this.filterComments)

			this.initCarousel()
		},
		updated() {
			$(`#dropdown-trigger-${this.post.id}`).dropdown({
				alignment: 'right',
				coverTrigger: true,
				constrainWidth: false
			})
		},		
		methods: {
			getFilteredEmojis(take = 3) {
					return _.take(this.post.emojis, take)
			},

			initCarousel() {
				try {
					var myCarousel = document.getElementById(`postSlides-${this.post.id}`)
					var carousel = new bootstrap.Carousel(myCarousel)
				} catch (e) {
					
				}
			},

			async onShare() {
				let response = await this.reactForm.submit('post', route('api.posts.share', this.post.id))
				this.$set(this, 'post', response.post)

				this.$swal({
					icon: 'success',
					text: response.message		
				})				
			},
			toggleComment() {
				this.showComment = !this.showComment
			},
			async filterComments(payload) {
				this.$set(this.form, 'targets', payload)

				await this.refreshComments()
			},
			getTranslations() {
					let translations = []
					Object
							.keys(this.post.translations.body)
							.forEach(code => {
									if (code != this.post.parent_locale) {
											translations.push({
													code,
													text: this.post.translations.body[code]
											})
									}
							})
					return translations
			},			
			toggleTranslation(translation) {
					this.$set(this.post, 'showTranslation', !this.post.showTranslation)

					if (this.post.showTranslation) {
							this.$set(this.post, 'current_translation_code', translation.code)
					} else {
							this.$set(this.post, 'current_translation_code', null)
					}
					
					this.$set(this.post, 'displayedTranslation', translation.text)
			},			
			updateReaction({post}) {
				this.$set(this.post, 'emojis', post.emojis)
			},
			onNotify() {
				this.$bus.$emit('posts:notification:show', this.post)
			},			
			updateAuthor(payload) {
				this.$set(this.form, 'author_id', payload)
			},
			onReacted(payload) {
				this.$set(this.reactForm, 'emoji', payload)
				this.reactForm
					.submit('put', route('posts.reactable', this.post.id))
					.then(response => {
						this.$set(this, 'post', response.post)
					})
			},
			getComments() {
				this.form
					.get(this.commentsSource)
					.then(response => {
						this.$set(this, 'comments', this.comments.concat(response.data))
						this.$set(this, 'pagination', response.meta)
						this.$set(this, 'links', response.links)						
					})
			},
			async updatePost() {
				this.fetch
					.get(route('api.posts.show', this.post.id))
					.then(response => {
						this.$set(this, 'post', response.post)
					})
			},
			async refreshComments() {
				await this.updatePost()

				let response = await this.form.get(route('post_comments.index', this.post.id))

				this.$set(this, 'comments', response.data)
				this.$set(this, 'pagination', response.meta)
				this.$set(this, 'links', response.links)
			},

			getVideoTech(video) {
				if (video.hasOwnProperty('source')) {
					return ['html5', video.source]
				}

				return ['html5']
			}
		}
	}
</script>