<template>
    <div :style="depth_margin" class="chat-user animate fadeUp delay-1">
        <div :id="`user-section-${node.id}`" class="user-section">
            <div class="w-100 valign-wrapper">
                <div class="w-100 comment-content-container">
                    <div :ref="`user-section-${node.id}`" :class="comment.id==comment_to_show ? 'media-content enfasy' : 'media-content'">
                        <p class="post-comment-author blue-grey-text text-darken-4 font-weight-700">
                            <i class="mr-1 medium material-icons">account_circle</i>
                            {{node.author.name}}
                        </p>

                        <div v-if="node.images" class="display-flex flex-column justify-content-center">
                            <div
                                class="card-attachment-image"
                                :style="{ backgroundImage: `url('${image.url}')`, backgroundRepeat: 'no-repeat' }"
                                v-for="image in comment.images" :key="image.id"
                            ></div>
                        </div>
                        <div v-if="comment.videos" class="display-flex flex-column justify-content-center">
                            <template v-for="video in comment.videos">
                                <video-player
                                    :key="video.id"
                                    :src="video.url"
                                    :options="{
                                        controls: true,
                                        fluid: true,
                                        fill: true,
                                        aspectRatio: '16:9',
                                        sources: [{src:video.url, type:video.mime}]
                                    }"
                                ></video-player>
                            </template>
                        </div>
                        <div v-if="comment.audios" class="display-flex flex-column justify-content-center">
                            <template v-for="audio in comment.audios">
                                <audio-player :key="audio.id" :media="audio"></audio-player>
                            </template>
                        </div>
                        <div class="m-0 info-text">
                            <div v-html="node.body"></div>
                            <template v-if="node.showTranslation">
                                <blockquote class="mt-2 mark animated-fadeIn" v-html="node.displayedTranslation"></blockquote>
                            </template>
                            <template v-if="node.permissions.see_translations">
                                <div class="d-flex justify-content-between">
                                    <template v-for="translation in getNodeTranslations(node)">
                                        <a href="javascript:void(0);" :class="{'display-none': node.current_translation_code && translation.code != node.current_translation_code}" class="mt-2 mr-2" v-if="hasTranslation" @click="toggleTranslation(node, translation)">
                                            <span v-if="!node.showTranslation" v-text="showTranslationLabel"></span>
                                            <span v-else v-text="showCloseTranslationLabel"></span>
                                            <i class="ml-1 flag-icon" :class="[`flag-icon-${translation.code}`]"></i>
                                        </a>
                                        <template v-if="node.permissions.see_translation_mode">
                                            <template v-if="node.translated_by.hasOwnProperty(translation.code)">
                                                (<span v-text="node.translated_by[translation.code]"></span>)
                                            </template>
                                        </template>
                                    </template>
                                </div>
                            </template>

                            <template v-if="node.permissions.notify_comment">
                                <a v-if="!node.its_me" :id="`comment-dropdown-trigger-${node.id}`" data-alignment="right" class="notify-dropdown dropdown-trigger btn-flat" href='#' :data-target="`#comment-dropdown-${node.id}`"><i class="material-icons">more_vert</i></a>
                                <ul v-if="!node.its_me" :id="`#comment-dropdown-${node.id}`" class="dropdown-content">
                                    <li><a @click="onDirectMessage(node, $event)" data-target="slide-out-chat" class="display-flex sidenav-trigger" href="#!">{{pmText}}</a></li>
                                    <li><a class="display-flex" @click="onCreateChat(node)" href="#!">{{pmModsText}}</a></li>
                                </ul>
                            </template>
                        </div>
                        <div
                            class="media-reaction"
                            :style="reactionStyle"
                            :id="`media-reaction-${node.id}`"
                        >
                            <template v-if="theme == 'facebook'">
                                <div class="feed feed-list">
                                    <div class="reaction-list-box">
                                        <template v-for="reaction in node.emojis">
                                            <span
                                                :key="reaction.id.value"
                                                :class="[reaction.id.value]"
                                                class="reaction-list-icon show"
                                            ></span>
                                        </template>
                                    </div>
                                </div>
                            </template>
                            <template v-else>
                                <reaction-list
                                    @unreactable="onReacted"
                                    :emojis="node.full_emojis_list"
                                    :show-counter="false"
                                ></reaction-list>
                            </template>
                        </div>
                    </div>
                    <div class="mt-1 mb-1 media-actions">
                        <template v-if="node.permissions.react_comment">
                            <div class="media-action-item">
                                <template v-if="theme == 'facebook'">
                                    <facebook-reaction
                                        :reactable="node"
                                        @reactable="onReacted"
                                    >
                                        <template #button>
                                            <span class="txt-gray-1">{{reactLabel}}</span>
                                        </template>
                                    </facebook-reaction>
                                </template>
                                <template v-else>
                                    <reactable
                                        @reacted="onReacted"
                                        :reactable="node"
                                        :label="reactLabel"
                                    ></reactable>
                                </template>
                            </div>
                        </template>
                        <div class="media-action-item">
                            <span v-if="node.permissions.write_comment" class="clickable" @click="onReply">
                                {{replyText}}
                            </span>
                        </div>
                        <slot name="comment-time" :node="node">
                            <div class="media-action-item">
                                <span>{{node.created_at}}</span>
                            </div>
                        </slot>
                    </div>

                    <div v-if="replies.length > 0" class="mt-1 replies-tree-container">
                    	<template v-for="reply in replies">
                    		<comment-node
                                :key="reply.id"
                                :comment="reply"
                                :pm-text="pmText"
                                :pm-mods-text="pmModsText"
                                :reply-text="replyText"
                                :load-more-reply-text="loadMoreReplyText"
                                :write-reply-text="writeReplyText"
                                :send-button-text="sendButtonText"
                                :react-label="reactLabel"
                                :theme="theme"
                                :post_to_show= "post_to_show"
                                :comment_to_show= "comment_to_show"
                                :all_replies="replies"
                            >
                                <template #comment-time="{node}">
                                    <div class="media-action-item">
                                        <span class="font-weight-normal">{{node.diff_for_humans}}</span>
                                    </div>
                                </template>
                            </comment-node>
                    	</template>
                    </div>

                    <div v-if="loadMore" class="load-replies-container mt-1">
                        <span class="clickable" @click="onLoadReplies">
                            {{loadMoreReplyText}}
                        </span>
                    </div>

                    <div v-show="showReply && node.permissions.write_comment" :id="`commetable_${node.id}`" class="mt-1 animate fadeUp delay-1 commentable-container">
                        <comment-textarea
                            :drag-drop-text="dragDropText"
                            :form-id="node.commentable_id"
                            :placeholder="writeReplyText"
                            @created="refreshReplies"
                            :button-text="sendButtonText"
                            :draft-source="draftSource"
                        ></comment-textarea>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Reactable from '../Commons/Reactable'
    import ReactionList from '../Commons/ReactionList'
	import FacebookReaction from '../Commons/FacebookReaction'
    import VideoPlayer from '../Commons/VideoPlayer'
    import AudioPlayer from '../Commons/AudioPlayer'
    import {Form} from '../../helpers/Form/Form'
import { log } from 'video.js'

	export default {
		name: 'comment-node',
		props: {
			comment: Object,
            pmText: String,
            pmModsText: String,
            replyText: String,
            loadMoreReplyText: String,
            writeReplyText: String,
            sendButtonText: String,
            reactLabel: String,
            showTranslationLabel: String,
            showCloseTranslationLabel: String,
            dragDropText: String,
            theme: String,
            post_to_show: String,
            comment_to_show: String,
            all_replies : {
                type : Array,
                default: () => []
            }


		},
        components: {
            Reactable,
            ReactionList,
            VideoPlayer,
            AudioPlayer,
            FacebookReaction
        },
        data() {
            return {
                load_more_flag : true,
                reactionStyle: {},
                showTranslation: false,
                depth_margin: null,
                node: this.comment,
                showReply: false,
                replies: [],
                pagination: {},
                links: {},
                form: new Form({}),
                reactForm: new Form({
                    emoji: null,
                    class_path: this.comment.class_path
                }),
                threadForm: new Form({
                    id: null,
                    room_id: null,
                    comment: this.comment
                }),
                loaded_children: 0
            }
        },
        computed: {
            loadMore() {
                var rply =  this.all_replies.filter(x=>x.parent_id== this.comment.id)
                console.log(this.comment.children, rply);

                if (rply.length>= this.comment.children  ){
                        return false

                } else if (!_.isEmpty(this.pagination)) {
                    return this.node.children >this.loaded_childred && this.pagination.current_page < this.pagination.last_page
                }
                return this.node.children > 0
            },
            source() {
                if (!_.isEmpty(this.links)) {
                    return this.links.next
                }
                return route('comment_replies.index', this.comment.id)
            },
            chainFromId() {
/*
                if (!_.isEmpty(this.links)) {
                    return this.links.next
                }*/


                return route('comment_chain.index',{ "comment" : this.node.id, "replyId": parseInt(this.comment_to_show)})

            },
            draftSource() {
                return route('comment_replies.draft', this.comment.id)
            },
            hasTranslation() {
                let translations = this.comment.translations
                if (Array.isArray(translations.body)) {
                    return false
                }

                return translations.body.hasOwnProperty('it')
            }
        },
        mounted() {
            this.setDepthMargin()
            $(`#comment-dropdown-trigger-${this.node.id}`).dropdown({
                alignment: 'right',
                coverTrigger: true,
                constrainWidth: false
            })
            if(!isNaN(parseInt(this.comment_to_show)) && !isNaN(parseInt(this.post_to_show))) {
               if(parseInt(this.post_to_show)==this.comment.id) this.onLoadRepliesById()
            }
            window.onresize = () => {
                this.setDepthMargin()
            }
            this.$bus.$on(`${this.node.class_path}-${this.node.id}`, this.updateReaction)

            this.$nextTick(() => this.getMediaReactionStyle())
            this.scrollToPost()
        },
        methods: {
            scrollToPost(){
                if(this.comment.id==this.comment_to_show){
                    var el =this.$refs["user-section-"+this.node.id]
                    if(el) el.scrollIntoView({behavior: 'smooth'});
                }

            },
            getMediaReactionStyle() {
                let style = {right: '-12px'}
                const el = document.getElementById(`media-reaction-${this.node.id}`)
                const parent = document.getElementById(`user-section-${this.node.id}`)
                if (el) {
                    if (el.parentNode.offsetWidth < parent.offsetWidth) {
                        let multiply = parseInt(el.offsetWidth / 20)
                        style = {right: `-${multiply*22}px`}
                    }
                }
                this.$set(this, 'reactionStyle', style)
            },
            getNodeTranslations(node) {
                let translations = []
                Object
                    .keys(node.translations.body)
                    .forEach(code => {
                        if (code != node.parent_locale) {
                            translations.push({
                                code,
                                text: node.translations.body[code]
                            })
                        }
                    })
                return translations
            },
            toggleTranslation(node, translation) {
                this.$set(this.node, 'showTranslation', !node.showTranslation)

                if (node.showTranslation) {
                    this.$set(this.node, 'current_translation_code', translation.code)
                } else {
                    this.$set(this.node, 'current_translation_code', null)
                }

                this.$set(this.node, 'displayedTranslation', translation.text)
            },
            setDepthMargin() {
                let margin = {marginLeft: `${this.node.depth * 15}px`}
                if (window.isMobile()) {
                    margin = {marginLeft: `${this.node.depth * 2}px`}
                }

                this.$set(this, 'depth_margin', margin)
            },
            toggleDetail(e) {
                let $trigger = $(e.target).closest('.sidenav-trigger');
                if (e.target && $trigger.length) {
                    let sidenavId = M.getIdFromTrigger($trigger[0]);

                    let sidenavInstance = document.getElementById(sidenavId).M_Sidenav;
                    if (sidenavInstance) {
                        sidenavInstance.open($trigger);
                    }
                    e.preventDefault();
                }
            },
            updateReaction({comment}) {
                this.$set(this.node, 'emojis', comment.emojis)
            },
            onCreateChat(item) {
                this.$bus.$emit('mods:thread:init-partecipants', {item})
            },
            onDirectMessage(item, ev) {
                this.$set(this.threadForm, 'room_id', item.room_id)

                this.threadForm
                    .submit('post', route('threads.store'))
                    .then(response => {
                        this.$bus.$emit('refresh-threads', {id: 'messages'})
                        this.$bus.$emit('thread:init', {item, thread: response.data})
                        this.toggleDetail(ev)
                    })
            },
            onReacted(payload) {
                this.$set(this.reactForm, 'emoji', payload)
                this.reactForm
                    .submit('put', route('comments.reactable', this.node.id))
                    .then(({comment}) => {
                        this.$set(this.node, 'emojis', comment.emojis)
                        this.$set(this.node, 'full_emojis_list', comment.full_emojis_list)
                    })
            },
            refreshReplies() {
                this.showReply = !this.showReply
                this.form
                    .get(route('comment_replies.index', this.node.id))
                    .then(response => {
                        this.$set(this, 'replies', response.data)
                        this.$set(this, 'pagination', response.meta)
                        this.$set(this, 'links', response.links)

                        this.$set(this.node, 'descendants', this.pagination.total)
                    })
            },
            onReply() {
                let node = this.node
                $(".commentable-container").each(function() {
                    let id = $(this).attr('id').split('_')

                    if (id[1] != node.id) {
                        $(this).hide()
                    }
                })

                this.showReply = true
                this.$bus.$emit(`prefix-user_${this.node.commentable_id}`, {
                    user: this.node.author.name,
                    source: this.node.source_reply,
                    data: this.node
                })

                $(`div#commetable_${this.node.id}`).show()
            },
            onLoadRepliesById() {
                this.form
                    .get(this.chainFromId)
                    .then(response => {
                        this.$set(this, 'replies', this.replies.concat(response.data))
                       // this.$set(this, 'pagination', response.meta)
                      //  this.$set(this, 'links', response.links)
                    })
            },
            onLoadReplies() {
                this.form
                    .get(this.source)
                    .then(response => {
                        //this.$set(this, 'replies', this.replies.concat(response.data))
                        const filteredReplies = response.data.filter(reply => reply.parent_id === this.node.id && !this.replies.find(x=>x.id ==reply.id) );
                        this.replies.push(...filteredReplies);
                        //this.$set(this, 'pagination', response.meta)
                        this.$set(this, 'links', response.links)
                    })
            },
        }
	}
</script>
<style lang="css" scoped>
.enfasy {
    background-color: #cacaca;
}
</style>
