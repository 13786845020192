<script>
	import {Form} from '../../helpers/Form/Form'

	export default {
		props: {
			url: String,
			code: String
		},
		data() {
			return {
				interval: null,
				fetch: new Form({
					code: this.code
				})
			}
		},
		mounted() {
			this.interval = setInterval(() => {
				this.getStatus()
			}, 5000)
		},
		methods: {
			async getStatus() {
				let response = await this.fetch.get(route('api.surveys.ssi_status'))

				if (response.status) {
					clearInterval(this.interval)
					
					await this.$swal({
						icon: 'success',
						title: response.message			
					})

					location.href = route('home')
				}
			}
		}
	}
</script>