import { Errors } from './Errors';

export class Form {
    /**
     * Create a new Form instance.
     *
     * @param {object} data
     */
    constructor(data) {
        this.originalData = data;

        for (let field in data) {
            this[field] = data[field];
        }

        this.errors = new Errors();
        this.submitted = false;
    }

    setData(field, value, index = null) {
        if (_.isArray(this[field])) {
            if (! _.isNull(index)) {
                this[field][index] = value;
            } else {
                this[field].push(value);
            }
        } else {
            this[field] = value;
        }
    }

    setAllData(data) {
        for (let property in data) {
            this.setData(property, data[property]);
        }
    }

    /**
     * Fetch all relevant data for the form.
     */
    data() {
        let data = {};

        for (let property in this.originalData) {
            data[property] = this[property];
        }

        return data;
    }

    /**
     * Reset the form fields.
     */
    reset(except = []) {
        this.submitted = false;

        for (let field in this.originalData) {
            this[field] = this.originalData[field]
        }

        this.errors.clear();
    }

    /**
     * Send a POST request to the given URL.
     * .
     * @param {string} url
     */
    post(url) {
        return this.submit('post', url);
    }

    /**
     * Send a PUT request to the given URL.
     * .
     * @param {string} url
     */
    put(url) {
        return this.submit('put', url);
    }

    /**
     * Send a PATCH request to the given URL.
     * .
     * @param {string} url
     */
    patch(url) {
        return this.submit('patch', url);
    }

    /**
     * Send a DELETE request to the given URL.
     * .
     * @param {string} url
     */
    delete(url) {
        return this.submit('delete', url);
    }

    /**
     * Submit the form.
     *
     * @param {string} requestType
     * @param {string} url
     * @param {object} headers
     * @param {boolean} keep
     */
    submit(requestType, url, headers = {}, keep = false) {
        this.errors.clear();
        this.submitted = !this.submitted;

        return new Promise((resolve, reject) => {
            axios[requestType](url, this.data(), headers)
                .then(response => {
                    this.onSuccess(response.data, keep);

                    resolve(response.data);
                })
                .catch(error => {
                    this.onFail(error.response.data.errors);

                    reject(error.response.data);
                });
        });
    }

    /**
     * Submit the form.
     *
     * @param {string} requestType
     * @param {string} url
     * @param {object} customdata
     * @param {boolean} keep
     */
    submitWith(requestType, url, customData = {}, headers = {}, keep = false) {
        this.errors.clear();
        this.submitted = !this.submitted;

        return new Promise((resolve, reject) => {
            axios[requestType](url, customData, headers)
                .then(response => {
                    this.onSuccess(response.data, keep);

                    resolve(response.data);
                })
                .catch(error => {
                    this.onFail(error.response.data.errors);

                    reject(error.response.data);
                });
        });
    }

    get(url, customData = {}, keep = false) {
        this.errors.clear();
        this.submitted = !this.submitted;

        let data = _.isEmpty(customData) ? this.data() : Object.assign(this.data(), customData);

        return new Promise((resolve, reject) => {
            axios.get(url, {
                params: data
            })
            .then(response => {
                this.onSuccess(response.data, keep);

                resolve(response.data);
            })
            .catch(error => {
                console.log(error);

                this.onFail(error.response.data.errors);

                reject(error.response.data);
            });
        });
    }

    /**
     * Handle a successful form submission.
     *
     * @param {object} data
     * @param {boolean} keep
     */
    onSuccess(data, keep) {
        this.submitted = !this.submitted;

        if (!this.hasOwnProperty('id')) {
            this.reset();
        }
    }

    /**
     * Handle a failed form submission.
     *
     * @param {object} errors
     */
    onFail(errors) {
        this.submitted = !this.submitted;

        this.errors.record(errors);
    }
}
