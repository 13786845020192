<script>
	import Datepicker from '../Commons/Datepicker'	
	import Dropzone from '../Commons/Dropzone'
	import {Form} from '../../helpers/Form/Form'

	export default {
		props: {
			category: {
				type: Object,
				default() {
					return {}
				}
			},
		},		
		components: {Dropzone,Datepicker},
		data() {
			return {
				targets: [],
				editor: null,
				fetch: new Form({}),
				form: new Form({
					id: null,
					name: null,
					description: null,
					position: null,
					show_target: false,
					show_publish: false,
					publish_at: null,
					show_unpublish: false,
					unpublish_at: null,
					targets_list: [],
					cover: null
				})
			}
		},
		computed: {
			source() {
				return !this.category.hasOwnProperty('id') ? route('categories.store') : route('categories.update', this.category)
			},
			method() {
				return !this.category.hasOwnProperty('id') ? 'post' : 'put'
			},
		},
		watch: {
			'form.show_unpublish': {
				handler(val) {
					if (!val) {
						this.$set(this.form, 'unpublish_at', null)
					}
				}
			},
			'form.show_publish': {
				handler(val) {
					if (!val) {
						this.$set(this.form, 'publish_at', null)
					}
				}				
			}
		},
		mounted() {
			this.init()
		},
		methods: {
			initPlugin() {
				let vm = this

				$("select").formSelect();
			},			
			async init() {
				await this.getTargets()

				this.initPlugin()
				this.initEditor()			
				this.initForm()	
			},		
			initForm() {
				for (let prop in this.category) {
					if (this.form.hasOwnProperty(prop)) {
						this.$set(this.form, prop, this.category[prop])
					}
				}

				if (this.category) {
					this.$set(this.form, 'show_publish', !_.isNull(this.form.publish_at))
					this.$set(this.form, 'show_unpublish', !_.isNull(this.form.unpublish_at))
					this.$set(this.form, 'show_target', this.form.targets_list.length > 0)

					this.editor.root.innerHTML = this.form.description					
				}
			},
			async getTargets() {
				let response = await this.fetch.get(route('api.targets.index'))
				this.$set(this, 'targets', response.targets)
			},			
			initEditor() {
				if ($(".ql-toolbar").length) {
					return
				}

				this.editor = new Quill(this.$refs.editor, {
					bounds: this.$refs.editor,
					modules: {
					  	'formula': true,
					  	'syntax': true,
					  	'toolbar': {
					  		container: [
							    [{'font': []}, {'size': []}],
						    	['bold', 'italic', 'underline', 'strike'],
						    	[{'color': []}, {'background': []}],
						    	[{'script': 'super'}, {'script': 'sub'
						    	}],
						    	[{'header': '1'}, {'header': '2'}, 'blockquote', 'code-block'],
						    	[{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
						    	['direction', {'align': []}],
						    	['link', 'image', 'video', 'formula'],
						    	['clean'],
						    	['emoji']
					  		],		  		
					  	},
						'emoji-toolbar': true,
						'emoji-shortname': true,			  	
					},
					theme: 'snow'
				});
				// add browser default class to quill select 
				let quillSelect = $("select[class^='ql-'], input[data-link]");
				quillSelect.addClass("browser-default");

		        this.editor.on('text-change', (e) => {
			    	this.$set(this.form, 'description', this.editor.root.innerHTML);
			    });		

			    this.editor.root.innerHTML = this.form.description
			},			
			async onSubmit() {
				await this.form
					.submit(this.method, this.source)
					.then(response => {
						if (!_.isNumber(this.form.id)) {
							location.href = route('categories.index')
						} else {
							this.$swal({
								icon: 'success',
								text: response.message			
							})							
						}
					})
			}
		}
	}
</script>